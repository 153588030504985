@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

:root {
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body {
  font-family: "Noto Serif";
  color: var(--secondary);
}
.App {
  max-width: 960px;
  margin: 0 auto;
}
.title h1 {
  color: var(--primary);
  margin: 40px 200px 40px;
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
  
}

.title h1:hover {
  color: gray;
}

.title h2,
.title p {
  text-align: center;
  color: var(--primary);
}
.title h2 {
  margin-top: 60px;
  margin-bottom: 80px;
  font-size: 2.6rem;
  text-decoration-thickness: 3px;
  border-bottom: 3px solid var(--primary);
  padding-bottom: 3px;
  border-width: 0 0 1px;
  margin: 40px auto 80px;
  width: 200px;


  
}

/* upload form styles */
form {
  margin: 30px auto 10px;
  text-align: center;
}
label input {
  height: 0;
  width: 0;
  opacity: 0;
}
label {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: var(--primary);
  font-weight: bold;
  font-size: 24px;
}
label:hover {
  background: var(--primary);
  color: white;
}
.output {
  height: 60px;
  font-size: 0.8rem;
}
.error {
  color: var(--error);
}

/* progress bar styles */
.progress-bar {
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  width: 100%;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  /* display: block;
position : absolute;
top: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
min-width: 100%;
min-height: 100%; */

  max-width: 30%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 150%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.navbar {
  background-color: white;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: grey;
}

.nav-menu {
  background-color: white;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;

}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: gray;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  color: black;
}

.nav-menu-items {
  color: gray;
  text-decoration: none;
  
}

.navbar-toggle {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}



